<template>
  <v-container fluid>
    <section>
      <v-row>
        <v-col>
          <div class="square primary">primary</div>
        </v-col>
        <v-col>
          <div class="square secondary">secondary</div>
        </v-col>
        <v-col>
          <div class="square accent">accent</div>
        </v-col>
        <v-col>
          <div class="square error">error</div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row>
        <v-col>
          <btn :minWidth="'250px'">Boutton 1</btn>
        </v-col>
        <v-col>
          <btn :filled="false">Boutton 2</btn>
        </v-col>
        <v-col>
          <btn :icon="'home'">Boutton 3</btn>
        </v-col>
      </v-row>
    </section>

    <section>
      <page-heading
        :title="'Titre personnalisé plus grand pour voir le retour à la ligne'"
        :subtitle="'Avec sous-titre'"
      >
        <btn large :icon="'home'" :minWidth="'250px'">Action 1</btn>
        <btn large :icon="'download'" :filled="false" :minWidth="'250px'"
          >Action 2</btn
        >
      </page-heading>
    </section>

    <section>
      <page-title
        :title="'Example'"
        :subtitle="'Remplissage en 2 minutes'"
      ></page-title>
    </section>

    <section>
      <section-title>Un titre en exemple</section-title>
    </section>

    <section>
      <person
        :name="'Pec'"
        :functions="'Lead Developper'"
        :image="require('../../../assets/img/avatar/sample.jpg')"
      ></person>
    </section>

    <section>
      <quote>
        <p>
          lfsmdlfsmùdlfmùsdlfmùslfmùslfsldfùsl ldfùls ùmflsù lsdmflsdmfl sùmdfls
          mùdlfùsmflsmùdlfsmls lsmfùlsdù mflsdmùflsdùmfl sùdfl smdlf
          mùsldfmùsldfmù lsdmfl smùdfls mùdlfmùs lmfùlsdmùflsmùdflsùmdflùsmldf
          mùlsdmfùlsmùd lmùs lfsmdlfsmùdlfmùsdlfmùslfmùslfsldfùsl ldfùls ùmflsù
          lsdmflsdmfl sùmdfls mùdlfùsmflsmùdlfsmls lsmfùlsdù mflsdmùflsdùmfl
          sùdfl smdlf mùsldfmùsldfmù lsdmfl smùdfls mùdlfmùs
          lmfùlsdmùflsmùdflsùmdflùsmldf mùlsdmfùlsmùd lmùs
          lfsmdlfmùslfmùslfsldfùsl ldfùls ùmflsù lsdmflsdmfl sùmdfls
          <strong>fsmùdlfmùsdl</strong> mùdlfùsmflsmùdlfsmls lsmfùlsdù
          mflsdmùflsdùmfl sùdfl smdlf mùsldfmùsldfmù lsdmfl smùdfls mùdlfmùs
          lmfùlsdmùflsmùdflsùmdflùsmldf mùlsdmfùlsmùd
        </p>
        <p>
          lfsmdlfsmùdlfmùsdlfmùslfmùslfsldfùsl ldfùls ùmflsù lsdmflsdmfl sùmdfls
          mùdlfùsmflsmùdlfsmls lsmfùlsdù mflsdmùflsdùmfl sùdfl smdlf
          mùsldfmùsldfmù lsdmfl smùdfls mùdlfmùs lmfùlsdmùflsmùdflsùmdflùsmldf
          mùlsdmfùlsmùd lmùs lfsmdlfsmùdlfmùsdlfmùslfmùslfsldfùsl ldfùls ùmflsù
          lsdmflsdmfl sùmdfls mùdlfùsmflsmùdlfsmls lsmfùlsdù mflsdmùflsdùmfl
          sùdfl smdlf mùsldfmùsldfmù lsdmfl smùdfls mùdlfmùs
          lmfùlsdmùflsmùdflsùmdflùsmldf mùlsdmfùlsmùd lmùs
          lfsmdlfsmùdlfmùsdlfmùslfmùslfsldfùsl ldfùls ùmflsù lsdmflsdmfl sùmdfls
          mùdlfùsmflsmùdlfsmls lsmfùlsdù mflsdmùflsdùmfl sùdfl smdlf
          mùsldfmùsldfmù lsdmfl smùdfls mùdlfmùs lmfùlsdmùflsmùdflsùmdflùsmldf
          mùlsdmfùlsmùd lmùs
        </p>
      </quote>
    </section>
    <section>
      More coming
    </section>
  </v-container>
</template>

<script>
export default {
  name: 'Integration',

  components: {
    // 'aaaa': () => import('../../../core/aaaa.vue'),
    btn: () => import('../../../core/BasicBtn.vue'),
    'page-heading': () => import('../../../core/PageHeading.vue'),
    'page-title': () => import('../../../core/PageTitle.vue'),
    'section-title': () => import('../../../core/Title.vue'),
    person: () => import('../../../core/Person.vue'),
    quote: () => import('../../../core/Citation.vue')
  }
}
</script>

<style scoped>
.square {
  height: 100px;
  width: 100px;
}
</style>
